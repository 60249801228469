import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { DynamicStaticImage } from '../components/ArtistAtSchool/dynamicStaticImage'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import { festifAtSchoolData, festifAtSchoolMeta } from '../data/festif-at-school-data'
import { graphql } from 'gatsby'

const FestifAlEcole = ({ data }) => {
  return (
    <div className="!relative" id="ecole">
      <FestifHelmet title={festifAtSchoolMeta.title}
                    description={festifAtSchoolMeta.description}
                    keywords={festifAtSchoolMeta.keywords}
                    imageUrl='https://images.prismic.io/le-festif-ca/167dee9a-c2c0-4f70-a440-59bd7b543a0c_og_fae.png' />

      {/* BANNER AND MENU */}
      <div className="text-center bg-[#f2e7ca]">
        <div className="!relative inline-block w-full">
          <div>
            <StaticImage
              src="../images/festifAtSchool/banner.png"
              alt="Le Festif! à l'école"
              height={1098}
              className="max-w-[1920px] hidden"/>
            <div className='md:h-36 h-12'>
              <a className="!absolute lg:top-10 top-4 lg:left-10 left-4" href="/le-festif-a-l-ecole/">
                <StaticImage
                  src="../images/festifAtSchool/top_logo.png"
                  alt="Le Festif! à l'école logo"
                  className="lg:w-[308px] w-[120px]" />
              </a>
            </div>
            <div className="text-right">
              <a className="!absolute lg:top-10 top-4 lg:right-10 right-4" href="/">
                <StaticImage
                  src="../images/festifAtSchool/logo_festif.png"
                  alt="Le Festif de Baie-Saint-Paul logo"
                  className="lg:w-[230px] w-[120px]" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="!relative bg-[#f2e7ca]">
        <StaticImage
          src="../images/festifAtSchool/nuages.png"
          alt="Le Festif à l'école logo"
          className="!absolute md:top-[-500px] top-8 !w-full z-10 !h-full" />

        {/* ARTISTS */}
        <div className="!relative">
          <div className="relative z-20 hidden">
            <center>
              <div className="lg:px-24 px-6 m-0 max-w-[1745px]">
                <p className="font-poppins font-black lg:text-[64px] text-[36px] text-[#5255c6] py-8">LES ARTISTES</p>
                <div className="mb-[20px]">

                  {festifAtSchoolData.map((schoolArtist) => {
                    return (
                    <div key={schoolArtist.slug} className="inline-block max-w-[325px] lg:w-[calc(20%-20px)] w-[calc(50%-20px)] lg:ml-[20px] ml-[10px] mb-[10px] lg:mb-[20px]">
                      <a href={`/le-festif-a-l-ecole/${schoolArtist.slug}`}>
                        <DynamicStaticImage
                          localPath={schoolArtist.getImageButtonLocalPath}
                          alt={schoolArtist.name}/>
                      </a>
                    </div>
                    )
                  })}
                </div>
              </div>
            </center>
          </div>

          {/* ABOUT THE PROJECT */}
          <div className='py-1'>
            <div>
              <StaticImage
                src="../../images/festifAtSchool/bg_about.png"
                alt="about"
                className="!absolute bottom-0 w-full h-full" />
            </div>
            <center>
              <div className="z-10 !relative lg:px-24 px-6 grid lg:grid-cols-4 gap-x-12 lg:ml-[20px] ml-[10px] mt-24 lg:mb-24 mb-12 max-w-[1500px]">
                <div className="lg:col-span-2">
                  <p className="font-poppins font-black lg:text-left text-center lg:text-[64px] text-[28px] mb-12 leading-none text-[#5255c6]">À PROPOS DU PROJET</p>
                </div>
                <div className="lg:row-span-6 lg:col-span-2">
                  <center>
                    <StaticImage
                      src="../images/festifAtSchool/about.png"
                      alt="À propos du projet"
                      className="max-h-[600px] lg:float-right float-none lg:mb-0 mb-6 z-30" />
                  </center>
                </div>
                <div className="leading-snug text-left text-[#5255c6]">
                  <p className="font-openSans font-bold text-[16px]">Se déclinant en plusieurs volets (spectacles gratuits, conférences et ateliers musicaux), cette série de rencontres permettra aux élèves de côtoyer plusieurs artistes établis, de se familiariser avec le métier d'auteur.e-compositeur.e-interprète, et de s'impliquer dans la vie culturelle de leur milieu.</p>
                  <br/>
                  <p className="font-openSans text-[16px] lg:mb-0 mb-4">Depuis toujours, l’équipe du Festif ! souhaite redonner à sa communauté et sensibiliser les jeunes à l’importance de soutenir la musique du Québec.</p>
                </div>
                <div className="leading-snug text-left text-[#5255c6]">
                  <p className="font-openSans font-regular text-[16px] lg:mb-0 mb-4">C’est dans cet esprit que Le Festif ! à l’École fut créé à l’automne 2018. Le projet permet aux jeunes de recevoir des artistes québécois marquant.e.s et ce, directement dans leur école.</p>
                  <br/>
                  <p className="font-openSans font-regular text-[16px]">Alors que la première édition s’est déroulée dans une seule école (Centre Éducatif St-Aubin, Baie-St-Paul), la 7e édition se déroulera dans 80 écoles de plusieurs régions de partout en province.</p>
                </div>
              </div>
                <center>
              <div className="relative grid z-30 max-w-[1500px] pb-8 leading-snug">
                  <div className="!relative">
                    <div className="bg-[#d9326e] rounded-3xl px-12 py-6 lg:float-left lg:max-w-[44%] lg:ml-[4%] lg:mr-[2%] lg:mb-0 max-w-[92%] mx-[4%] mb-6">
                      <p className="text-white font-black font-poppins lg:text-[45px] text-[28px] lg:text-left text-center uppercase">Nouveauté</p>
                      <p className="text-white font-openSans text-[16px] text-left">Préparations disponibles pour les écoles et les élèves ! Analyse des paroles de chansons, analyse de la musique et questions pour l’artiste, il y a tout ce qu’il vous faut pour être prêt à recevoir Le Festif! à l’École dans votre établissement.</p>
                    </div>
                    {/* <a href="https://601bf9c7.sibforms.com/serve/MUIFAFfMFpYt4UnJNQrdSHQPXJG7lmoF64wLcEneuGEjPfwpolOnYlYHbXshK0JRQUhuw904oWVpvj4sRgWeyyWMarhaMNyCHyCWch7bk4x3-tLYDIhZ8gMkpHL_W2NHhapWENW0vhA73jCnN_XXh7y5xS98nkHp7wJw8mGeGOgxSZpbEpsrXBxCvTOWJj0GjO8vZj5lQlY5T38Q"> */}
                      <div className="bg-[#5255c6] rounded-3xl px-12 py-6 lg:float-right lg:max-w-[44%] lg:mr-[4%] lg:ml-[2%] lg:mb-0 max-w-[92%] mx-[4%] mb-6">
                        <p className="text-white font-black font-poppins lg:text-[45px] text-[28px] lg:text-left text-center uppercase">Formule</p>
                        <p className="text-white font-openSans text-[16px] text-left">Écoles secondaires et cégeps : 2 performances de 25 minutes, suivi d'un atelier/conférence (au choix de l'artiste) de 60 minutes.<br/><br/>Écoles primaires : 4 performances de 25 minutes</p>
                      </div>
                    {/* </a> */}
                  </div>
              </div>
                </center>
            </center>
          </div>
        </div>

        {/* VIDEOS */}
        <center>
          <div className="!relative lg:px-24 px-6 my-24 grid xl:grid-cols-3 z-10 max-w-[1500px]">
          <div className="mx-4 lg:mb-12 mb-4">
              <div className="!relative pb-[56.25%]">
                <iframe
                  id="3"
                  title="Youtube 3"
                  className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                  src="https://www.youtube.com/embed/oOZD7t4oDW4"
                  allowFullScreen=""
                  referrerPolicy="origin-when-cross-origin" />
              </div>
              <p className="font-poppins font-black text-[#5255c6] xl:text-[28px] text-[20px]">À PROPOS DU PROJET</p>
              <p className="font-openSans text-[#5255c6] text-[16px]">Pourquoi s’inscrire au Festif! à l’École</p>
            </div>
            <div className="mx-4 mb-12">
              <div className="!relative pb-[56.25%]">
                <iframe
                  id="1"
                  title="Youtube 1"
                  className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                  src="https://www.youtube.com/embed/IA2-x9kg2GI"
                  allowFullScreen=""
                  referrerPolicy="origin-when-cross-origin" />
              </div>
              <p className="font-poppins font-black text-[#5255c6] xl:text-[28px] text-[20px]">C'EST ÇA LE FESTIF!</p>
              <p className="font-openSans text-[#5255c6] text-[16px]">Le Festif! à l'école partie 1</p>
            </div>
            <div className="mx-4 mb-12">
              <div className="!relative pb-[56.25%]">
                <iframe
                  id="2"
                  title="Youtube 2"
                  className="!absolute top-0 left-0 w-[100%] h-[100%] pb-4"
                  src="https://www.youtube.com/embed/pKIltqNFKfc"
                  allowFullScreen=""
                  referrerPolicy="origin-when-cross-origin" />
              </div>
              <p className="font-poppins font-black text-[#5255c6] xl:text-[28px] text-[20px]">C'EST ÇA LE FESTIF!</p>
              <p className="font-openSans text-[#5255c6] text-[16px]">Le Festif! à l'école partie 2</p>
            </div>
          </div>
        </center>

        {/* PARTENAIRES */}
        <center>
          <div className="!relative lg:px-24 px-6 z-10 max-w-[1415px]">
            <center className="">
              <p className="font-poppins font-black lg:text-[44px] text-[24px] text-[#5255c6]">MERCI À NOS PARTENAIRES</p>
              <p className="uppercase font-poppins font-bold text-black mt-8">Présentateur</p>
              <a href="https://www.hydroquebec.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="px-2">
                <StaticImage className="max-w-[200px] mt-2"
                            src="../../images/festifAtSchool/hydroquebec.png"
                            alt="Hydro-Québec" />
              </a>
              <div className="lg:pt-8 py-4 pb-24 flex flex-wrap justify-center items-center">
                { data.allPrismicFestifatschoolsponsor.edges.map((sponsorData) => {
                  const sponsor = sponsorData.node.data
                  const logo = getImage(sponsor.image)
                  return (
                    <a
                      key={sponsor.link.url}
                      href={sponsor.link.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="px-2"
                    >
                      <GatsbyImage
                        className="w-[75px] mb-4"
                        image={logo}
                        alt={sponsor.alt ?? sponsor.name.text ?? 'Partenaire'}
                      />
                    </a>
                  )
                })}
              </div>
            </center>
          </div>
        </center>

        <StaticImage
          src="../../images/festifAtSchool/bg_partenaires.png"
          alt="Partenaires"
          className="!absolute bottom-0 w-[100%]" />

      </div>

    </div>
  )
}

export default FestifAlEcole

export const festifAtSchoolSponsorResult = graphql`
query {
  allPrismicFestifatschoolsponsor {
    edges {
      node {
        data {
          image {
            gatsbyImageData
            alt
          }
          name {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
}
`
