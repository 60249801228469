import React from 'react'
import { Helmet } from 'react-helmet'

const FestifHelmet = (meta) => {
  const defaultTitle = 'Le Festif! de Baie-Saint-Paul'
  const defaultDescription = 'Du 17 au 20 juillet 2025, Le Festif! de Baie-St-Paul présentera sa 16e édition.'
  const defaultKeywords = 'festival, musique, événement, spectacles, québec, charlevoix'
  const defaultUrl = 'https://lefestif.ca'

  const title = meta.title || defaultTitle
  const description = meta.description || defaultDescription
  const keywords = meta.keywords || defaultKeywords
  const imageUrl = meta.imageUrl || null
  const href = meta.href || defaultUrl
  const path = meta.path
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {imageUrl ? <meta property="og:image" content={imageUrl} /> : null}
        <meta property="og:url" content={path ? `https://lefestif.ca/${path}` : href} />
        <meta property="og:type" content="website" />
        {
          (process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true)
            ? <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            : null
        }
      </Helmet>
    </div>
  )
}

export default FestifHelmet
